import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import * as styles from './SocialShare.module.css';

const SocialShare = ({ label, url, text, imageUrl }) => {
    let outputUrl = url;
    if (!outputUrl && typeof window !== "undefined") {
        outputUrl = `${window.location.origin}${window.location.pathname}`;
    }
    let outputText = text;
    if (!outputText && typeof document !== "undefined") {
        outputText = document.title.split('|')[0];
    }
    return (
        <>
            {label && <span className={styles.socialsLabel}>{label}</span>}
            <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(outputUrl)}`}
                target="_blank"
                rel="noreferrer"
                className={styles.inline}
            >
                <Icon symbol="facebookblack" />
            </a>
            <a
                href={`https://twitter.com/intent/tweet?url=${encodeURI(outputUrl)}&text=${encodeURIComponent(outputText)}`}
                target="_blank"
                rel="noreferrer"
                className={styles.inline}
            >
                <Icon symbol="twitter" />
            </a>
            {imageUrl && (
                <a
                    href={`https://pinterest.com/pin/create/button/?url=${encodeURI(outputUrl)}&media=${imageUrl}&description=${encodeURIComponent(outputText)}`}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.inline}
                >
                    <Icon symbol="pinterest" />
                </a>
            )}
        </>
    );
};

export default SocialShare;
