import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/organisms/Layout/Layout';
import BlogPost from '../components/organisms/BlogPost/BlogPost';
import { transformContentfulPost } from '../helpers/contentful';
import BlogListsContext from '../context/BlogListsProvider';
import PostListContentful from '../components/organisms/PostListContentful/PostListContentful';
import Container from '../components/atoms/Container/Container';

const ContentfulPost = ({ data }) => {

  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const blogListsContext = useContext(BlogListsContext);

  useEffect(() => {
    const currentPost = !data.postEvents ? data.postNews : data.postEvents;    
    // const currentPost = data.postNews;
    setPost(currentPost);

    const key = (data.postEvents === null) ? 'news' : 'events';
    if (blogListsContext && key in blogListsContext) {
      const filterPost = [];
      blogListsContext[key].map((item) => {
        if (item.node.id !== currentPost.id) {
          filterPost.push(item);
        }
        return item;
      });
      setRelatedPosts(filterPost.slice(0, 3));
    }
  }, [data, blogListsContext]);

  return (
    <Layout mainClass='bottomMargin' seo={{
      title: get(post, 'blogTitle', null), 
      image: get(post, 'blogFeaturedImage.file.url', null)
    }}>
      {post && (
        <BlogPost post={transformContentfulPost(post)} />
      )}
      {relatedPosts && relatedPosts.length > 0 && (
        <Container>
          <PostListContentful
            posts = {relatedPosts}
            viewMode = {'grid'}
            cardSize = {'large'}
            titleOnly = {false}
          />
        </Container>
      )}
    </Layout>
  );
};

ContentfulPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ContentfulPost;

export const query = graphql`
  query ContentfulBlogPostByID($id: String!) {
    
    postNews: contentfulBlogPostNews(id: { eq: $id }) {
      id
      blogTitle
      slug
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      blogFeaturedImage {
        file {
          url
          fileName
        }
      }
      blogContent {
        raw
      }
      updatedAt(formatString: "MMMM DD, YYYY")
    }
  }
`;
