// extracted by mini-css-extract-plugin
export var postCard = "BlogPostCardContentful-module--postCard--LvhrG";
export var topCategories = "BlogPostCardContentful-module--topCategories--jwLHY";
export var listView = "BlogPostCardContentful-module--listView--Y8tpt";
export var postImageContainer = "BlogPostCardContentful-module--postImageContainer--zTw+6";
export var postTitle = "BlogPostCardContentful-module--postTitle--mKapw";
export var postImage = "BlogPostCardContentful-module--postImage--EzId9";
export var gridView = "BlogPostCardContentful-module--gridView--HMBoX";
export var postBody = "BlogPostCardContentful-module--postBody--WKdzC";
export var postDate = "BlogPostCardContentful-module--postDate--pshQ6";
export var postDateTime = "BlogPostCardContentful-module--postDateTime--Dlyzu";
export var postContent = "BlogPostCardContentful-module--postContent--fR8je";
export var readMore = "BlogPostCardContentful-module--readMore--v4ALC";
export var categories = "BlogPostCardContentful-module--categories--fj4jQ";