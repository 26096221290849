import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { handleLink } from '../../../helpers/general';

import * as styles from './BlogPostCardContentful.module.css';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';

const BlogPostCardContentful = ({
  title,
  content,
  thumbnail,
  slug,
  viewMode,
  size,
  titleOnly,
  date
}) => {
  // console.log(JSON.parse(content).content[0]);
  // console.log(JSON.parse(content).content[0].content[0].value);
  const excerptStr = useMemo(() => {
    const parsedDesc = JSON.parse(content).content[0].content[0];
    const str = parsedDesc ? parsedDesc.value : '';
    if (str.length > 80) {
      return str
      .substring(0, 80)
      .concat('...');
    } else {
      return str;
    }
  }, [content]);

  const classes = useMemo(() => {
    const initClass = [styles.postCard];
    if (viewMode === 'grid') {
      initClass.push(styles.gridView);
    } else {
      initClass.push(styles.listView);
    }
    if (size) {
      initClass.push(styles[size]);
    }
    return initClass.join(' ');
  }, [viewMode, size]);

  const thumbnailUrl = useMemo(() => {
    if (thumbnail) {
      return thumbnail.replace('http:', 'https:');
    }

    return thumbnail;
  }, [thumbnail])

  const slugUrl = useMemo(() => {
    if (slug) {
      return handleLink(`/blog/${slug}`);
    }
  }, [slug])
  
  const titleSub = title.length >= 35 ? title.substring(0, 35).concat('...') : title;

  return (
    <div className={classes}>
      <Link to={slugUrl}>
        <div className={styles.postImageContainer}>
          <img
            loading="lazy"
            className={styles.postImage}
            src={thumbnailUrl}
            alt={title}
          />
        </div>
      </Link>
      <div className={styles.postBody}>
        <div className={styles.postTitle}>
          {slug && (
            <Link to={slugUrl}>
              <h6>{titleSub}</h6>
            </Link>
          )}
          {!slug && <h6>{titleSub}</h6>}
          {!titleOnly && (
            <div className={styles.postDate}>
              {excerptStr && (
                <div>
                  <span
                    className={styles.postContent}
                    dangerouslySetInnerHTML={{
                      __html: excerptStr
                    }}
                  />
                </div>
              )}
              <span className={styles.postDateTime}>{date}</span>
            </div>
          )}
        </div>
        {slug && (
          <div>
            <span className={styles.readMore}>
              {slug && 
              <Link to={slugUrl}>
                <ContentfulTranslationText keyName="readMore">
                  read more
                </ContentfulTranslationText>
              </Link>}
            </span>
          </div>
        )} 
      </div>
    </div>
  );
};

BlogPostCardContentful.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  thumbnail: PropTypes.string,
  slug: PropTypes.string,
  date: PropTypes.string,
  viewMode: PropTypes.string,
  size: PropTypes.string
};

BlogPostCardContentful.defaultProps = {
  excerpt: '',
  content: '',
  size: 'medium'
};

export default BlogPostCardContentful;
