// extracted by mini-css-extract-plugin
export var blog = "BlogPost-module--blog--dECn0";
export var breadCrumb = "BlogPost-module--breadCrumb--IAcMs";
export var topCategories = "BlogPost-module--topCategories--oTD60";
export var postImageContainer = "BlogPost-module--postImageContainer--V3aye";
export var postImage = "BlogPost-module--postImage--f0Erm";
export var postBody = "BlogPost-module--postBody--6whyu";
export var postTitle = "BlogPost-module--postTitle--5QAgP";
export var postDate = "BlogPost-module--postDate--07dsZ";
export var postContent = "BlogPost-module--postContent--q4-aW";
export var link = "BlogPost-module--link--zpvom";
export var socials = "BlogPost-module--socials--D6P1Y";
export var categories = "BlogPost-module--categories--1tyvS";