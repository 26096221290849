import React, { useContext, useEffect, useMemo, useState } from 'react';
import { get } from "lodash";
import PropTypes from 'prop-types';
import Container from '../../atoms/Container/Container';
import SocialShare from '../../molecules/SocialShare/SocialShare';
import { handleLink } from '../../../helpers/general';
import * as styles from './BlogPost.module.css';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import ContentfulContext from '../../../context/ContentfulProvider';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

const BlogPost = ({ post }) => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});
    const title = post.title;
    // const slug = post.slug;
    // const authorName = post.author_name;
    // const authorUri = post.author_uri;
    const date = post.published_date;
    const content = post.content;
    const thumbnail = post.thumbnail_path;
    const customizedSlug = handleLink(`/blog/${post.slug}`); // ? post.slug.replace('/blogs', '/blog') : ''; // Currently the slug has the initial blog word in plural form -- JR
    const blogType = post.type === 'blogPost' ? 'News' : 'Events';
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    // const categories = useMemo(() => {
    //     return post.categories || [];
    // }, [post]);

    // let beautifiedContent = '';
    // JSON.parse(content).content.forEach(e => {
    //     switch (true) {
    //         case e.nodeType === 'paragraph':
    //             if (e.content.length > 1) {
    //                 beautifiedContent += `<p>`;
    //                 e.content.forEach(f => {
    //                     // console.log(f);
    //                     if (f.nodeType === 'hyperlink') {
    //                         beautifiedContent += `<a href="${handleLink(f.data.uri)}" class="${styles.link}">${f.content[0].value}</a>`;
    //                     } else if (f.marks && f.marks.length > 0) {
    //                         beautifiedContent += (f.marks[0].type === 'bold' ? `<strong>${f.value}</strong>` : `${f.value}`);
    //                     } else {
    //                         beautifiedContent += `${f.value}`;
    //                     }
    //                 })
    //                 return beautifiedContent += `</p>`;
    //             }

    //             return beautifiedContent += `<p>${e.content[0].value}</p>`;
    //         case e.nodeType === 'heading-4':
    //             return beautifiedContent += `<h4>${e.content[0].value}</h4>`;
    //         case e.nodeType === "embedded-asset-block":
    //             let embededUrl = post.contentAsset.filter(x => x.contentful_id === e.data.target.sys.id).map(x => x.file.url);
    //             return beautifiedContent += `<div><img src="${embededUrl}"></div>`;
    //         default:
    //             return '';
    //     }
    // });
    // console.log(content);

    // const timeToRead = useMemo(() => {
    //     if (content) {
    //       return readTime(content);
    //     }
    //     return 0;
    // }, [content]);

    // const categoriesTags = useMemo(() => {
    //     if (categories) {
    //       return categories.map((cat, catIdx) => (
    //         <Link to={cat.uri} key={catIdx}>
    //           {cat.name}
    //         </Link>
    //       ));
    //     }
    //     return [];
    // }, [categories]);

    const thumbnailUrl = useMemo(() => {
        if (thumbnail) {
          return thumbnail.replace('http:', 'https:');
        }
    
        return thumbnail;
    }, [thumbnail])

    useEffect(() => {
        if (blogType && breadcrumbs.length === 0) {
            let breadcrumbData = [];
            breadcrumbData.push({link: '/', label: get(translations, 'home.title', 'Home')});
            breadcrumbData.push({link: `/blog#${blogType}`, label: get(translations, blogType === 'Events' ? 'events.title' : 'news.title', blogType)});
            setBreadcrumbs(breadcrumbData);
        }
    }, [blogType, breadcrumbs, translations]);

    return (
        <>
            <div className={styles.postImageContainer}>
                <img
                    loading="lazy"
                    className={styles.postImage}
                    src={thumbnailUrl}
                    alt={title}
                />
            </div>
            <Container>
                <div className={styles.blog}>
                    <Breadcrumbs crumbs={breadcrumbs} />
                    <div className={styles.postBody}>
                        <div className={styles.postTitle}>
                            <h5>{title}</h5>
                            <div className={styles.postDate}>
                                <span>
                                {/* {date} - {timeToRead} min Read */}
                                {date}
                                </span>
                            </div>
                        </div>
                        <div className={styles.socials}>
                            <SocialShare imageUrl={thumbnailUrl} url={`${process.env.SITE_URL}${customizedSlug}`} text={title}/>
                        </div>
                        <div className={styles.postContent}>
                        {content && (
                            // <div
                            //     className={styles.postContent}
                            //     dangerouslySetInnerHTML={{
                            //     __html: beautifiedContent
                            //     }}
                            // />
                            <ContentfulRichText raw={content} />
                        )}
                        </div>
                    </div>
                    
                </div>
            </Container>
        </>
    );
};

BlogPost.propTypes = {
  post: PropTypes.shape({}).isRequired
};

export default BlogPost;
