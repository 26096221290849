
/**
 * BigCommerce API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 3 is assumed
 * 
 * @return  {Object}                {response, status}
 *
    import { bcApi } from '../helpers/bigcommerce'

    bcApi('endpoint', 'POST', bodyObject, version).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

function transformContentfulPost(post) {
  const post_url = post.blogTitle.replace(/[^a-zA-Z0-9]/g, "-")

  return {
    id: post.id,
    title: post.blogTitle,
    type: post.sys.contentType.sys.id,
    excerpt: post.blogContent.raw,
    slug: post.slug ? post.slug : `/blogs/${post_url}`,
    content: post.blogContent.raw,
    contentAsset: post.blogContent.references,
    author_name: post.blogTitle,
    author_uri: post.blogTitle, // TODO: need to find author uri
    published_date: post.updatedAt,
    thumbnail_path: (post.blogFeaturedImage && post.blogFeaturedImage.file.url) || 'https://via.placeholder.com/1903x450?text=No+Images+Available',
    tags: [],
    comments: []
    // categories: post.categories.nodes,
  };
}

export { 
    transformContentfulPost,
};
