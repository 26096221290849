import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import BlogPostCardContentful from '../../molecules/BlogPostCardContentful/BlogPostCardContentful';

import * as styles from './PostListContentful.module.css'

// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

const PostListContentful = ({ posts, viewMode, cardSize, titleOnly }) => {
  const classes = useMemo(() => {
    const initClass = [];
    if (viewMode === 'grid') {
      initClass.push(styles.postsGrid);
    } else {
      initClass.push(styles.postsList);
    }
    if (cardSize) {
      initClass.push(styles[cardSize]);
    }
    return initClass.join(' ');
  }, [viewMode, cardSize]);

  // console.log(posts);

  return (
    <>
      <div className={classes}>
        {posts && posts.map(post => (
          <BlogPostCardContentful
            size={cardSize}
            key={post.node.id}
            title={post.node.blogTitle}
            excerpt={post.node.excerpt}
            slug={post.node.slug} //post.node.blogTitle.replace(/[^a-zA-Z0-9]/g, "-") JR - FOR ACCURATE URL OF POST
            content={post.node.blogContent.raw}
            date={post.node.updatedAt}
            viewMode={viewMode}
            thumbnail={(post.node.blogFeaturedImage && post.node.blogFeaturedImage.file.url) || 'https://via.placeholder.com/453?text=No+Images+Available'}
            titleOnly={titleOnly}
          />
        ))}
      </div>
    </>
  );
};

PostListContentful.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  viewMode: PropTypes.oneOf(['list', 'grid'])
};

PostListContentful.defaultProps = {
  viewMode: 'list'
};

export default PostListContentful;